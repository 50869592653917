<template>
  <div class="app-container">
    <Header :showBuscador="true" @busqueda="busquedaNombre" @escribiendo="escribiendo"></Header>
    <DatosPaciente v-if="datosPaciente" :datos="datosPaciente" @editar="editar"></DatosPaciente>
    <main class="content" v-show="!showModalCarrito">
      <div class="categorias-container">
        <Categorias :categories="categories" :load="loadCategorias" @categoriaSeleccionada="getProductos"></Categorias>
      </div>
      <div class="estudio-container">
        <Estudios :estudios="estudios" :load="loadServicios" @agregar="estudioAgregado" v-if="estudios.length > 0 || loadServicios"></Estudios>
        <div v-if="estudios.length < 1 && !loadServicios" class="sin-resultados">
          <p>No se encontraron coincidencias en la busqueda</p>
        </div>
      </div>
    </main>
    <FooterCarrito
      v-show="!showModalCarrito"
      v-if="opcionRealizar == 1"
      @abrirCarrito="abrirCarrito"
    ></FooterCarrito>
  </div>
  <ModalCarrito
    v-if="showModalCarrito"
    @cerrar="cerrarCarrito"
    :nuevoServicio="nuevoServicio"
    @update:nuevoServicio="updateNuevoServicio"
  ></ModalCarrito>
  <AgregarPaciente v-if="showAgregar" @cerrar="cerrarAgregar" @regresar="cerrarAgregar" @agregado="agregado" :editar="true"> </AgregarPaciente>
  <ModalAltaCarrito v-if="showAnimacion"></ModalAltaCarrito>
</template>
<script>
import Header from "@components/Layouts/Header.vue";
import Categorias from "@components/Servicios/Categorias.vue";
import Estudios from "@components/Servicios/ContenedorCards.vue";
import FooterCarrito from "@components/Layouts/FooterCarrito.vue";
import ModalCarrito from "@components/Modal/Carrito.vue";
import CategoriasServices from "@services/categorias.js";
import ServicosServices from "@services/servicios.js";
import { useCarritoStore } from "@store/carritoStore";
import { useUserStore } from "@store/userStore.js";
import DatosPaciente from '@components/Layouts/DatosPaciente.vue';
import AgregarPaciente from '@components/Modal/AgregarPaciente.vue';
import ModalAltaCarrito from "@components/Modal/ModalAltaCarrito.vue";

export default {
  name: "ServiciosView",
  components: {
    Header,
    Categorias,
    Estudios,
    FooterCarrito,
    ModalCarrito,
    DatosPaciente,
    AgregarPaciente,
    ModalAltaCarrito
  },
  emits: [],
  props: {},
  mixins: [],
  data() {
    return {
      cartStore: useCarritoStore(),
      useUser: useUserStore(),
      showAgregar: false,
      nuevoServicio: false,
      carrito: [],
      showModalCarrito: false,
      loadCategorias: true,
      categories: [],
      loadServicios: true,
      idCategoriaBusqueda: null,
      estudios: [],
      showAnimacion: false,
    };
  },
  beforeMount() {
    this.useUser.guardarRuta('Servicios')
    this.cartStore.iniciarCarrito();
    this.getCategorias();
  },
  computed: {
    opcionRealizar() {
      return this.useUser.realizar;
    },
    datosPaciente() {
      return this.useUser.datosPaciente
    }
  },
  methods: {
    async getCategorias() {
      this.loadCategorias = true
      let cat = await CategoriasServices.getCategorias();
      if(!cat.error) {
          this.categories = cat.categorias
          this.loadCategorias = false
      } else {
        this.categories = []
          this.loadCategorias = false
      }
    },
    async getProductos(id) {
      this.idCategoriaBusqueda = id
      this.loadServicios = true
      let productos = await CategoriasServices.getServiciosById(id);
      if(!productos.error) {
          this.estudios = productos.servicios.data[0].services
          this.loadServicios = false
      } else {
          this.estudios = []
          this.loadServicios = false
      }
    },
    escribiendo() {
      this.loadServicios = true
      this.estudios = []
    },
    async busquedaNombre(buscar) {
      if(buscar == '') {
        this.getProductos(this.idCategoriaBusqueda)
      } else {
        this.loadServicios = true
        let busqueda = await ServicosServices.getBusquedaServicios(buscar);
        this.estudios = busqueda.busqueda
        this.loadServicios = false
      }
    },
    abrirCarrito() {
      this.showModalCarrito = true;
    },
    cerrarCarrito() {
      this.showModalCarrito = false;
    },
    estudioAgregado() {
      // this.nuevoServicio = true; 
      // this.showModalCarrito = true;
      this.showAnimacion = true
      setTimeout(() => {
        this.showAnimacion = false;
      }, 1500);
    },
    updateNuevoServicio() {
      // this.nuevoServicio = false;
    },
    editar() {
      this.showAgregar = true;
    },
    cerrarAgregar() {
      this.showAgregar = false
    },
    agregado() {
      this.showAgregar = false 
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.showModalCarrito && to.path !== '/resumen') {
      this.showModalCarrito = false; // Cerrar el modal
      next(false); // Evitar la navegación
    } else {
      next();
    }
  },
  created() {},
  unmounted() {},
  watch: {},
};
</script>
<style scoped>
.container {
  width: 100% !important;
}
.servicios {
  margin-top: 75px;
  width: 100%;
  padding: 0 25px;
}
.categorias-container {
  width: 100%;
}
.estudio-container {
  margin-top: 20px;
  max-height: calc(100vh - 300px);
  overflow: scroll;
  margin-bottom: 102px;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--primary-color);
}

::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
.module-container {
  margin-top: 65px !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}
.sin-resultados {
  display: flex;
  justify-content: center;
  height: 300px;
  align-items: center;
  text-align: center;
}
</style>
<style>
@media (min-width: 1279px) {
  .content {
    width: 100%;
  }
}
</style>