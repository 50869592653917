<template>
  <div class="app-container">
    <div v-if="load">
      <SplashScreen></SplashScreen>
    </div>
    <main class="content" v-if="!load">
      <div class="login">
        <img class="logo" src="@assets/logo-recetame.svg" alt="" />
        <div class="iniciarCopie">Iniciar sesión</div>
        <span class="labelInput">Usuario</span>
        <Input
          v-model="userTxt"
          class="input"
          type="text"
          id="inputCorreo"
          placeholder="Correo electrónico"
          :flagLogin="true"
          :flagAutoFocus="true"
          @keyup="keymonitor"
          @keypress="reglasCorreo"
          @blur="handleBlurCorreo"
          refDinamico="focus"
          ref="user"
          :maxlength="50"
          autocomplete="off"
          inputmode="email"
          v-bind:class="showMsjError ? 'input-error' : ''"
        />
        <span class="labelInput">Contraseña</span>
        <Input
          v-model="passTxt"
          class="input"
          :type="passShow ? 'password' : 'text'"
          id="inputPass"
          placeholder="Contraseña"
          :flagLogin="true"
          @keyup="keymonitorPass"
          @keypress="reglasCorreo"
          @blur="handleBlurPassword"
          refDinamico="focus"
          ref="pass"
          :maxlength="60"
          autocomplete="off"
        >
          <div
            class="ojo user-select-none"
            @click="showPassword"
            :class="!passShow ? 'active' : ''"
          >
            <i v-if="passShow" class="icon-ojo_bloq"></i>
            <i v-else class="icon-ojo"></i>
          </div>
        </Input>

        <span v-show="showMsjError" class="copie-error">{{ msjError }}</span>
        <Button
          id="btnIngresar"
          class="btn-activo"
          :isDiasable="!botonActivo"
          @click="loginBtn"
          :class="!botonActivo ? 'btnDesactivado' : ''"
          >Ingresar</Button
        >
      </div>
    </main>
  </div>
</template>
<script>
import Input from "@components/General/Form/Input.vue";
import Button from "@components/General/Form/Button.vue";
import controlMixin from "@mixins/control.js";
import loginService from "@services/login.js";
import SplashScreen from "@components/Splash.vue";
// import { useUserStore } from "@store/userStore.js";

export default {
  name: "LogIn",
  components: {
    Input,
    Button,
    SplashScreen
  },
  emits: [],
  props: {},
  mixins: [],
  data() {
    return {
      userTxt: "",
      passTxt: "",
      passShow: true,
      botonActivo: false,
      showMsjError: false,
      msjError: "El correo y/o contraseña son incorrectos.",
      load: false,
    };
  },
  beforeMount() {
    // this.load = true
    // setTimeout(() => {
    //   this.load = false;
    // }, 1000);
  },
  computed: {},
  methods: {
    async loginBtn() {
      this.showMsjError = false;
      try {
        const res = await loginService.login(this.userTxt.trim(), this.passTxt);
        if (res.error == true) {
          this.showMsjError = true;
          this.msjError = res.msj;
        } else {
          this.$router.push("/home")
        }
      } catch (error) {
        this.showMsjError = true;
        this.msjError = error.data;
      }
    },
    showPassword() {
      this.passShow = !this.passShow;
    },
    reglasCorreo: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 32) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    keymonitor: function (event) {
      if (this.userTxt != "") {
        if (event.key == "Enter") {
          this.setFocus(1);
        }
      }
    },
    keymonitorPass: function (event) {
      if (this.loading) {
        return;
      }
      if (this.passTxt != "") {
        if (event.key == "Enter") {
          this.activarBoton();
          // this.loginBtn();
        }
      }
    },
    setFocus(id) {
      if (id == 1) {
        let input = this.$refs.pass.$refs.focus;
        this.$nextTick(() => input.focus());
      } else {
        let input = this.$refs.user.$refs.focus;
        this.$nextTick(() => input.focus());
      }
    },
    handleBlurCorreo() {
      if (this.userTxt != "" && !this.validarCorreo()) {
        this.loginErrorMsg = "Correo electrónico inválido.";
      } else {
        this.loginErrorMsg = null;
      }
      this.activarBoton();
    },
    handleBlurPassword() {
      this.activarBoton();
    },
    validarCorreo() {
      if (
        !controlMixin.validarFormatoCorreo(this.userTxt?.toLowerCase().trim())
      ) {
        return false;
      }
      return true;
    },
    activarBoton() {
      let activo = false;
      if (
        this.userTxt != "" &&
        this.validarCorreo() &&
        this.passTxt.length >= 8
      ) {
        activo = true;
      }
      this.botonActivo = activo;
    },
  },
  created() {},
  unmounted() {},
  watch: {
    userTxt() {
      this.loginErrorMsg = null;
      this.showMsjError = false;
    },
    passTxt() {
      this.loginErrorMsg = null;
      this.showMsjError = false;
      if (this.passTxt.length >= 8) {
        this.activarBoton();
      }
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  padding: 40px !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  overflow-y: auto;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
}
.iniciarCopie {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  margin-top: 48px;
  margin-bottom: 50px;
}
.labelInput {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  align-items: flex-start;
  display: flex;
  width: 100%;
}
.input {
  width: 100%;
  margin-bottom: 21px;
}
#btnIngresar {
  margin-top: 25px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}
.ojo.active {
  color: var(--primary-color);
}

.ojo {
  position: absolute;
  top: calc(7px / 1);
  right: 5px;
  font-size: 17px;
  cursor: pointer;
  width: 30px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  color: var(--primary-color);
}
.ojo .icon-visualizar {
  display: flex;
  align-items: center;
}
.ojo i::before {
  width: 2em;
}
.copie-error {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #ff4141;
  opacity: 1;
  width: 100%;
  text-align: left;
}
.input-error {
  border: 1px solid #ff4141;
  border-radius: 5px;
}
.logo {
  width: 60%;
  min-width: 200px;
  max-width: 300px;
}
@media (min-width: 767px) {
  .login {
    width: 80%;
  }
}
</style>
