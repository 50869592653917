<template>
  <div class="contenedor">
    <div class="header">
      <div class="contenedor-izquierdo">
        <div class="contenedor-header-buscador" v-if="showBuscador">
          <Input
            v-model="buscar"
            class="input"
            type="text"
            id="inputNombre"
            placeholder="Buscar.."
            :maxlength="50"
            autocomplete="off"
            inputmode="text"
            height="40px"
            width="85%"
            :isBuscador="true"
            @handle="handleBuscar"
            @input="handleInput"
          >
            <div class="lupa-on">
              <div class="icon-lupa">
                <i class="icon-buscar"></i>
              </div>
            </div>
          </Input>
        </div>
        <div class="contenedor-header-logo" v-if="showLogo">
          <img alt="Vue logo" src="../../assets/logo-recetame.svg" />
        </div>
      </div>
      <div class="contenedor-derecho">
        <i class="icon-perfil" alt="icon" @click="toggleMenu"></i>
      </div>
      <FloatingMenu
        v-if="showMenu"
        :visible="showMenu"
        :options="menuOptions"
        :position="{ top: '20px', left: '0' }"
        @opcion="opcion"
      />
    </div>
  </div>
</template>


<script>
import FloatingMenu from "./MenuFlotante.vue";
import Input from "@components/General/Form/Input.vue";
import loginService from "@services/login.js";
import { useCarritoStore } from "@store/carritoStore";
import { useUserStore } from "@store/userStore.js";

export default {
  name: "HeaderVue",
  components: {
    FloatingMenu,
    Input,
  },
  props: {
    showBuscador: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buscar: '',
      tiempoBusqueda: null,
      time: 400,
      showMenu: false,
      menuOptions: [
        { label: "Inicio", handler: this.handleHome, icon: "home" },
        { label: "Reiniciar", handler: this.handleReiniciar, icon: "home" },
        {
          label: "Cerrar sesión",
          handler: this.handleLogout,
          icon: "cerrarsesion",
        },
      ],
      cartStore: useCarritoStore(),
      useUser: useUserStore(),
    };
  },
  emits: ['busqueda','escribiendo'],
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleHome() {
      this.$router.push("/home");
    },
    async handleLogout() {
      await loginService.logOut()
      this.$router.push("/login");
    },
    handleReiniciar() {
      this.useUser.reiniciar()
      this.cartStore.vaciarCarrito()
      this.$router.push("/home");
    },
    opcion(opcion) {
      console.log("opcion ", opcion);
    },
    handleInput() {
      this.$emit('escribiendo'); 
    },
    handleBuscar(buscador) {
      clearTimeout(this.tiempoBusqueda);
      this.tiempoBusqueda = setTimeout(() => {
        this.$emit('busqueda', buscador);
      }, this.time)  
    }
  }
};
</script>

<style scoped>
.contenedor {
  width: 100%;
  margin: auto;
  position: relative;
}
.header {
  height: 50px;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 2px 25px;
  justify-content: space-between;
  margin-top: 15px;
}
.contenedor-izquierdo {
  flex: 0 1 80%;
  display: flex;
  align-items: center;
}
.contenedor-header-buscador {
  width: 100%;
}
.contenedor-header-buscador > input {
  width: 95%;
  margin-right: 25px;
  justify-content: flex-start;
}
.contenedor-derecho {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.contenedor-header-logo {
  width: 95%;
  display: flex;
  justify-content: flex-start;
}
.contenedor-header-logo > img {
  width: 100px;
  height: 40px;
}
.icon {
  width: 20%;
}
.icon-perfil {
  font-size: 25px;
}

.lupa-on {
  transition: all 0.4s;
  opacity: 1;
  width: 100%;
  overflow: hidden;
}
.icon-lupa {
  position: absolute;
  top: -1px;
  right: 0px;
  width: 40px;
  height: 41px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 4px 4px 0px;
  opacity: 1;
  transition: all 0.4s;
}
.icon-buscar {
  width: 17px;
  transition: all 0.4s;
  color: white;
}
.input {
  border: 1px solid #a2a2a2;
  border-radius: 4px;
}
.input > input {
  height: 40px !important;
}
</style>