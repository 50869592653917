<template>
  <div class="app-container">
    <main class="content">
      <div class="home">
        <span class="copiePregunta">¿Qué deseas realizar el día de hoy?</span>
        <div class="opcion-realizar" @click="seleccion(1)">
          <img class="icon-opcion" src="@assets/icons/icon-receta.svg" alt="" />
          <span class="copie-opcion">Orden médica</span>
        </div>
        <div class="opcion-realizar" @click="seleccion(2)">
          <img
            class="icon-opcion-cotizacion"
            src="@assets/icons/icon-buscar-estudio.svg"
            alt=""
          />
          <span class="copie-opcion">Buscar estudio</span>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>
<script>
import Footer from "@components/Layouts/FooterGeneral.vue";
import { useUserStore } from "@store/userStore.js";
import PerfilServices from "@services/perfil.js"

export default {
  name: "Home",
  components: {
    Footer,
  },
  emits: [],
  props: {},
  mixins: [],
  data() {
    return {
      useUser: useUserStore()
    };
  },
  async beforeMount() {
    this.useUser.guardarRuta('Home')
    if(this.useUser.session.accessToken) {
      await PerfilServices.getPerfil() 
    }
  },
  computed: {},
  methods: {
    seleccion(opcion) {
      this.useUser.guardarOpcionRealizar(opcion)
      if(opcion == 1) {
        if(this.useUser.datosPaciente && !this.useUser.ultimoPaciente) {
          this.$router.push("/servicios")  
        } else {
          this.$router.push("/tipopaciente")
        }
      } else {
        this.useUser.guardarPaciente(false)
        this.useUser.existeUltimoPaciente(false) //Se cambia a false en cuanto selecciona que quiere que sea generica y no hasta finalizar
        this.$router.push("/servicios")
      }
    },
  },
  created() {},
  watch: {},
};
</script>
<style scoped>
.copiePregunta {
  display: flex;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  color: var(--primary-color);
  opacity: 1;
  padding: 30px;
  font-variant: normal;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0px;
  justify-content: center;
}
.opcion-realizar {
  width: 100%;
  height: 120px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px #70707038;
  cursor: pointer;
}
.icon-opcion {
  width: 55px;
  height: 55px;
  margin-bottom: 6px;
}
.icon-opcion-cotizacion {
  width: 47px;
  height: 58px;
  margin-bottom: 6px;
}
.copie-opcion {
  font-style: normal;
  font-variant: normal;
  font-weight: medium;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #050608;
}
.home {
  padding: 0px 40px;
  min-width: 95%;
}
</style>