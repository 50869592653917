import axios from 'axios';
import configService from './config';
import loginService from "@services/login.js";
import { useUserStore } from "@store/userStore.js";
import router from '../router';

const api = axios.create({
  baseURL: configService.BASE_URL,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    const authString = `Bearer ${token}`;
    config.headers.Authorization = authString;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log('refresh')
    const useUser = useUserStore();
    let originalRequest = error.config;
    if ((error.response.status === 400 || error.response.status === 401 || error.response.status === 404) && originalRequest.url === '/auth/refresh') {
      return Promise.resolve({ data: { error: true } });
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const res = await loginService.refresh();
        if (res.data.error === false) {
          const tokenRefresh = res.data.tokenRefresh;
          localStorage.setItem('token', tokenRefresh);
          useUser.guardarToken()
          return await api(originalRequest);
        } else {
          console.log('logout')
          useUser.logout();
          // loginService.logout();
          return Promise.reject(error);
        }
      } catch (error) {
          loginService.logOut();
          router.push('/')
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
export default api;
