<template>
  <ModalBase
    class=""
    :mostrarHeader="true"
    :mostrarCerrar="false"
    @cerrar="closeModal"
  >
    <div class="content" v-show="!loadData && !noEncontrada && mostrarView">
      <Button @click="printHmtl">Imprimir</Button>
      <html lang="en" id="printMe">
        <head>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>PDF Estudios</title>
          <link rel="stylesheet" href="/css/PDFEstudios.css" />
        </head>
        <body>
          <header>
            <div class="headerSection">
              <div class="logoAndName">
                <img src="../assets/logo-recetame.svg" alt="logo" />
              </div>
              <div class="addressAndName">
                <p class="nombre">Dr. Armando Espinoza Valenzuela</p>
                <p class="cedula">Cédula Profesional: 7368172</p>
                <p class="universidad">UNIVERSIDAD AUTÓNOMA DE SINALOA</p>

                <div class="addr">
                  <br />
                  <p class="domicilio">
                    <span>Padre Mier 1380 Pte. esq. Nicolás</span><br />
                    <span>Bravo, Col. Maria Luisa, Centro, C.P.</span><br />
                    <span>64040, Monterrey N.L.</span><br />
                    <span>Tel: 81 8150 2100</span>
                  </p>
                </div>
              </div>
              <div class="codigoBarra">
                <img
                  class="cbarra"
                  src="../assets/icons/img-codigo-barras.png"
                  alt=""
                />
              </div>
            </div>
            <hr />
            <div class="headerSection">
              <div class="issuedTo">
                <div class="header-datos-paciente">
                  <p class="title">
                    Nombre del paciente:
                    <span class="txt">{{ getNombre }}</span>
                  </p>
                  <p class="title">
                    Fecha: <span class="txt">{{getFecha}}</span>
                  </p>
                </div>
                <div class="header-datos-paciente-edad">
                  <p class="title">
                    Fecha de nacimiento: <span class="txt">{{getFechaNacimiento}}</span>
                  </p>
                  <p class="title">Edad: <span class="txt">{{calcularEdad}}</span></p>
                </div>
              </div>
            </div>
          </header>
          <main class="main">
            <div class="contenedor-estudios">
              <div v-for="(detalle, index) in detalle" :key="detalle.id">
                <div class="card-estudio">
                  <p class="nombre-estudio">
                    {{ index + 1 }} .-{{ detalle.description }}
                  </p>
                  <span class="lbl-indicaciones">Indicaciones</span>
                  <p class="txt-indicaciones">
                    {{ detalle.preparation }}
                  </p>
                  <p class="precio">
                   <span class="quantity">({{detalle.quantity}})</span> Precio: <span class="monto-precio">${{ getPrice(detalle.totalPrice) }}</span>
                  </p>
                </div>
                <div class="salto-linea" v-if="index == 3"></div>
              </div>
              <p class="copie-precios">*Los precios son válidos en la fecha de emisión del documento</p>
            </div>
          </main>
          <footer>
            <div style="height: 10cm; position: absolute"></div>
            <img src="../assets/logo-recetame.svg" alt="logo" />
            <div class="addr">
              <br />
              <p class="domicilio">
                <span>Padre Mier 1380 Pte. esq. Nicolás</span><br />
                <span>Bravo, Col. Maria Luisa, Centro, C.P.</span><br />
                <span>64040, Monterrey N.L.</span>
              </p>
            </div>
            <hr />
          </footer>
        </body>
      </html>
    </div>
    <div v-show="loadData" :class="loadData ? 'load': ''" v-for="i in 7" :key="i">
        <Skeleton  height="68px"></Skeleton>
    </div>
    <div v-show="noEncontrada" class="sin-receta">
      <p>No se encontró la receta.</p>
    </div>
    <div v-show="!loadData && !noEncontrada && !mostrarView" class="receta">
      <img src="@assets/icons/icon-orden_exito.svg" alt="">
      <p>Receta generada</p>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from "@components/General/Modal/ModalBase.vue";
import Button from "@components/General/Form/Button.vue";
import { useUserStore } from "@store/userStore.js";
import OrdenServices from '@services/orden.js';
import moment from 'moment';
import monto from '@mixins/monto.js'
import Skeleton from "@components/General/Load/Skeleton.vue";
import axios from 'axios';
import html2pdf from 'html2pdf.js';

export default {
  name: "PDFView",
  mounted() {
    // this.printHmtl();
  },
  components: {
    ModalBase,
    Button,
    Skeleton
  },
  props: {},
  data() {
    return {  
      mostrarView: false,
      noEncontrada: false,
      loadData: true,
      ordenid: null,
      orden: null,
      useUser: useUserStore(),
      detalle: [
      ],
    };
  },
  emits: ["cerrar"],
  computed: {
    getNombre() {
      let paciente = ''
      if(this.orden?.patient) {
        let pacienteAux = this.orden?.patient
        paciente = `${pacienteAux.name} ${pacienteAux.lastname_p} ${pacienteAux.lastname_m ? pacienteAux.lastname_m : ''}`
      } 
      return paciente
    },
    getFecha() {
      return this.orden?.createdAt ? moment(this.orden.createdAt).format('DD/MM/YYYY') : ''
    },
    getFechaNacimiento() {
      return this.orden?.patient ? moment(this.orden.patient.birthdate).format('DD/MM/YYYY') : ''
    },
     calcularEdad() {
      if (!this.orden?.patient) return '';
      const birthDate = moment(this.orden.patient.birthdate, 'YYYY-MM-DD');
      const hoy = moment(); 

      const edad = hoy.diff(birthDate, 'years');
      let format = `${edad} ${edad == 1 ? 'año' : 'años'}`
      return format
    },
  },
  async created() {
    // setTimeout(() => {
      this.ordenid = this.$route.params.token;
      if (this.ordenid && this.ordenid != "") {
        await this.getOrden();
        // setTimeout(() => {
          this.generatePDF()
        // }, 1000);  
      } else {
        this.ordenNoValida({
          status: -1,
          msj: "Por favor de ingresar una orden válida",
        });
      }
    // }, 500);
  },
  methods: {
    closeModal() {
      this.$emit("cerrar");
    },
    async printHmtl() {
      let imprimir = "";
      imprimir = document.getElementById("printMe");
      var ventana = "";
      let tituloImpresion = "Estudios PDF";

      ventana = window.open("", "PRINT", `height=800,width=600`);
      ventana.document.write(
        "<html><head><title>" + tituloImpresion + "</title>"
      );
      ventana.document.write(
        '<link rel="stylesheet" href="/css/PDFEstudios.css">'
      );
      ventana.document.write("</head><body >");
      ventana.document.write(imprimir.innerHTML);
      ventana.document.write("</body></html>");
      ventana.document.close();
      ventana.focus();
      setTimeout(() => {
        ventana.print();
      }, 200);
    },
    async getOrden() {
      this.noEncontrada = false
      const res = await OrdenServices.getOrderId(this.ordenid);
      if(!res.error) {
        this.orden = res.order ? res.order : null
        this.detalle = res.order.orderDetails ? res.order.orderDetails : []
        this.loadData = false
      } else {
        this.orden = null
        this.detalle = []
        this.loadData = false
        this.noEncontrada = true
      }
    },
    ordenNoValida(data) {
      console.log(data)
    },
    getPrice(precio) {
      return monto.formatPrice(precio)
    },
    generatePDF() {
      let creacion = moment(this.orden.createdAt).format('DD/MM/YYYY')
      let birthdate = this.orden?.patient ? moment(this.orden.patient.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
      let paciente = this.getNombre
      let edad = this.calcularEdad 
      
      axios.get('../formatorecetapdflink.html')
        .then(response => {
          let contenido = ``
          for(const [index, item] of this.orden.orderDetails.entries()) {
            contenido += 
            `
            <div class="card-estudio">
                <p class="nombre-estudio">${index+1}.-${item.description}</p>
                <span class="lbl-indicaciones">Indicaciones</span>
                <p class="txt-indicaciones">${item.preparation}</p>
                <p class="precio">
                <span class="quantity">(${item.quantity})</span> Precio: <span class="monto-precio">$${monto.formatPrice(item.totalPrice)}</span>
                </p>
            </div>
            `

            if (index == 6) {
              contenido +=
              `
              </div>
                <div class="footer-principal">
                  <img src="../logo-recetame.png" alt="logo" />
                  <div class="addr">
                      <br />
                      <p class="domicilio">
                          <span>Padre Mier 1380 Pte. esq. Nicolás</span><br />
                          <span>Bravo, Col. Maria Luisa, Centro, C.P.</span><br />
                          <span>64040, Monterrey N.L.</span>
                      </p>
                  </div>
                  <hr />
              </div>
              </div>
              <div class="salto-linea"></div>
            `
            contenido += this.hojaNueva(paciente, creacion, birthdate, edad )
            }
          }
          const htmlcontent = response.data
            .replace('{{ getNombre }}', paciente)
            .replace('{{ estudios }}', contenido)
            .replace('{{ getFecha }}', creacion )
            .replace('{{ getFechaNacimiento }}', birthdate)
            .replace('{{ calcularEdad }}', edad)

          const opt = {
            margin: [5, 5, 0, 5], // Márgenes superior, derecho, inferior, izquierdo
            filename: `Orden-${this.orden.folio}` + '.pdf',
            image: {
              type: 'jpeg',
              quality: 0.98
            },
            html2canvas: {
              scale: 2, logging: true, dpi: 192, letterRendering: true 
            },
            jsPDF: {
              unit: 'mm',
              format: 'a4',
              orientation: 'portrait'
            }
          };
          html2pdf().from(htmlcontent).set(opt).save();
        })
        .catch(error => {
        console.error(error);
      });
    },
    hojaNueva(nombre, fecha, nacimiento, edad) {
      let hoja = `
      <div class="hoja">
        <div class="header-principal">
            <div class="headerSection">
                <div class="logoAndName">
                    <img src="../logo-recetame.png" alt="logo" width="113" height="56" />
                </div>
                <div class="addressAndName">
                    <p class="nombre">Dr. Armando Espinoza Valenzuela</p>
                    <p class="cedula">Cédula Profesional: 7368172</p>
                    <p class="universidad">UNIVERSIDAD AUTÓNOMA DE SINALOA</p>

                    <div class="addr">
                        <br />
                        <p class="domicilio">
                            <span>Padre Mier 1380 Pte. esq. Nicolás</span><br />
                            <span>Bravo, Col. Maria Luisa, Centro, C.P.</span><br />
                            <span>64040, Monterrey N.L.</span><br />
                            <span>Tel: 81 8150 2100</span>
                        </p>
                    </div>
                </div>
                <div class="codigoBarra">
                    <img class="cbarra" src="../img-codigo-barras.png" alt="" />
                </div>
            </div>
            <hr />
            <div class="headerSection">
                <div class="issuedTo">
                    <div class="header-datos-paciente">
                        <p class="title">
                            Nombre del paciente:
                            <span class="txt-nombre">${ nombre }</span>
                        </p>
                        <p class="title">
                            Fecha: <span class="txt">${ fecha }</span>
                        </p>
                    </div>
                    <div class="header-datos-paciente-edad">
                        <p class="title">
                            Fecha de nacimiento:
                            <span class="txt">${ nacimiento }</span>
                        </p>
                        <p class="title">Edad: <span class="txt">${ edad } </span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contenedor-receta">
      `
          return hoja
    },

  },
};
</script>

<style scoped>
.load {
  margin: 20px 20px;
}
.content {
  z-index: 1004;
}
.modal {
  z-index: 1005;
}
.btn {
  display: flex;
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 100px;
  align-items: center;
  justify-content: center;
}
header {
  top: 130px;
  left: 0;
  right: 0;
  z-index: 1000;
}
header .headerSection {
  display: flex;
  justify-content: space-between;
}
.addressAndName > .nombre {
  font: normal normal bold 10px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}

.addressAndName > .cedula {
  font: normal normal 500 9px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}

.addressAndName > .universidad {
  font: normal normal normal 8px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}

.codigoBarra {
  width: 35%;
  justify-content: end;
  display: flex;
}

.addr > .domicilio {
  font: normal normal normal 11px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}

header .logoAndName {
  display: flex;
  justify-content: space-between;
}

header .logoAndName img {
  width: 90px;
  height: 40px;
  margin-right: 0.5cm;
}

header .headerSection .issuedTo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.cbarra {
  width: 190px;
  height: 90px;
}

.hr-container {
  width: 100%;
}

.header-datos-paciente {
  display: flex;
  justify-content: space-between;
}

.header-datos-paciente,
.header-datos-paciente-edad > .title {
  font: normal normal bold 10px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}

.title > .txt {
  font: normal normal 500 9px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}
.txt-nombre {
  font: normal normal 500 9px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
  text-transform: capitalize;
}
.sin-receta {
  display: flex;
  justify-content: center;
  margin: auto;
  height: 200px;
  align-items: center;
}
.receta {
  display: flex;
  justify-content: center;
  margin: auto;
  height: calc(100vh - 200px);
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}
.receta > p {
  margin-top: 20px;
}
.header-datos-paciente-edad {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.main {
  z-index: 999;
}
.nombre-estudio {
  text-align: left;
  font: normal normal bold 10px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}

.lbl-indicaciones {
  font: normal normal 500 8px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}

.txt-indicaciones {
  font: normal normal 500 8px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
}

.precio {
  display: flex;
  justify-content: end;
  font: normal normal bold 10px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
  margin-top: 16px;
}
.quantity {
  margin-right: 5px;
}
.monto-precio {
  margin-left: 5px;
}
.copie-precios {
  font: normal normal bold 8px/13px Roboto;
  letter-spacing: 0px;
  color: #132853;
  opacity: 1;
  margin-top: 5px;
}
footer {
  -prince-float: bottom;
  box-sizing: border-box;
}

footer p {
  margin: 0;
  column-count: 1;
}

footer img {
  width: 3.5cm;
  height: 1.5cm;
}
</style>
