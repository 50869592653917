<template>
  <div class="app-container" v-if="!agregarPaciente">
    <Header :showLogo="true"></Header>
    <DatosPaciente v-if="datosPaciente" :datos="datosPaciente" @editar="editar"></DatosPaciente>
    <main class="content">
      <p class="title">Resumen</p>
      <CardResumen></CardResumen>
    </main>
    <Footer @regresar="regresar" @finalizar="finalizar" v-show="!showCompartir"></Footer>
    <FooterCompartir v-if="showCompartir && !descargando" :permisos="permisosCopi" @cerrar="cerrarCompartir" @accion="accionCompartir"></FooterCompartir>
  </div>
  <ConfirmacionSinPaciente v-if="agregarPaciente" @confirmado="confirmado"></ConfirmacionSinPaciente>
  <ModalExito v-if="showModalExito" @cerrar="cerrarExito" :msjExito="msjExito"></ModalExito>
  <ModalQr v-if="showModalQr"  @cerrar="cerrarQr" :link="linkWeb"></ModalQr>
  <!-- <PDFEstudios v-if="showModalPDF" @cerrar="cerrarPDF" :ordenGenerada="ordenGenerada"></PDFEstudios> -->
  <AgregarPaciente v-if="showAgregar" @cerrar="cerrarAgregar" @regresar="cerrarAgregar" @agregado="agregado" :editar="true"> </AgregarPaciente>
  <AgregarDatoFaltante v-if="modalFaltante" :dato="faltante" @cerrar="cerrarFaltante" @dato="confirmarDato"></AgregarDatoFaltante>
</template>
<script>
import Header from "@components/Layouts/Header.vue";
import { useCarritoStore } from "@store/carritoStore";
import { useUserStore } from "@store/userStore.js";
import Footer from '@components/Layouts/FooterModalResumen.vue';
import CardResumen from "@components/Resumen/CardsResumen.vue";
import ConfirmacionSinPaciente from '@components/Resumen/ConfirmacionSinPaciente.vue';
import FooterCompartir from '@components/Layouts/FooterCompartir.vue';
import ModalExito from '@components/Modal/ModalExito.vue';
import ModalQr from '@components/Modal/ModalQr.vue';
// import PDFEstudios from '@components/Resumen/PDFEstudios.vue';
import moment from 'moment';
import OrdenServices from '@services/orden.js'
import { useClipboard, usePermission } from '@vueuse/core'
import DatosPaciente from '@components/Layouts/DatosPaciente.vue';
import AgregarPaciente from '@components/Modal/AgregarPaciente.vue';
import AgregarDatoFaltante from "@components/Modal/ModalAgregarFaltantePaciente.vue";
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import monto from '@mixins/monto.js'

const { isSupported } = useClipboard()
const permissionRead = usePermission('clipboard-read')
const permissionWrite = usePermission('clipboard-write')

export default {
  name: "ServiciosView",
  components: {
    Header,
    Footer,
    CardResumen,
    ConfirmacionSinPaciente,
    FooterCompartir,
    ModalExito,
    ModalQr,
    // PDFEstudios,
    DatosPaciente,
    AgregarPaciente,
    AgregarDatoFaltante
  },
  emits: [],
  props: {},
  mixins: [],
  data() {
    return {
      cartStore: useCarritoStore(),
      useUser: useUserStore(),
      agregarPaciente: false,
      showCompartir: false,
      showModalExito: false,
      showModalQr: false,
      showModalPDF: false,
      linkWeb: null,
      linkWhatsApp: null,
      ordenGenerada: null,
      msjExito: '',
      permisosCopi: false,
      showAgregar: false,
      sinPaciente: null,
      faltante: null,
      modalFaltante: false,
      descargando: false
    }
  },
  beforeMount() {
    this.cartStore.iniciarCarrito();
    this.useUser.guardarRuta('Resumen')
    if(isSupported) {
      if(permissionRead && permissionWrite) {
        this.permisosCopi = true
      }
    }
  },
  computed: {
    datosPaciente() {
      return this.useUser.datosPaciente
    },
    getNombre() {
      let paciente = ''
      if(this.ordenGenerada?.patient) {
        let pacienteAux = this.ordenGenerada?.patient
        paciente = `${pacienteAux.name} ${pacienteAux.lastname_p} ${pacienteAux.lastname_m ? pacienteAux.lastname_m : ''}`
      } 
      return paciente
    },
    getFecha() {
      return this.ordenGenerada?.createdAt ? moment(this.ordenGenerada.createdAt).format('DD/MM/YYYY') : ''
    },
    getFechaNacimiento() {
      return this.ordenGenerada?.patient ? moment(this.ordenGenerada.patient.birthdate).format('DD/MM/YYYY') : ''
    },
     calcularEdad() {
      if (!this.ordenGenerada?.patient) return '';
      const birthDate = moment(this.ordenGenerada.patient.birthdate, 'YYYY-MM-DD');
      const hoy = moment(); 

      const edad = hoy.diff(birthDate, 'years');
      let format = `${edad} ${edad == 1 ? 'año' : 'años'}`
      return format
    },
  },
  methods: {
   regresar(){
    this.$router.push('/servicios')
   },
   finalizar() {
    if (!this.useUser.paciente) {
        this.agregarPaciente = true;
      } else {
        // this.showCompartir = true
        this.generarOrden()
      }
   },
   cerrarCompartir() {
    this.showCompartir = false
    if(this.useUser.paciente) {
      this.useUser.existeUltimoPaciente(true)
    } else {
      this.useUser.existeUltimoPaciente(false)
    }
    this.cartStore.vaciarCarrito()
    this.useUser.guardarOpcionRealizar(null)
    this.$router.push('/home')
   },
   confirmado() {
    this.agregarPaciente = false
    this.generarOrden()
   },
   async generarOrden() {
    if(this.useUser.paciente) {
      var patient = {
        name: this.useUser.paciente.nombre.trim(),
        lastname_p: this.useUser.paciente.apellidoPaterno.trim(),
        lastname_m: this.useUser.paciente.apellidoMaterno ? this.useUser.paciente.apellidoMaterno.trim() : '',
        phone: this.useUser.paciente.telefono,
        email: this.useUser.paciente.correo ? this.useUser.paciente.correo.trim() : '',
        birthdate: moment(this.useUser.paciente.fechaNacimiento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        sex: this.useUser.paciente.sexo,
        id: this.useUser.paciente.id ? this.useUser.paciente.id : null
      }
    }
    
    let datos = {
      patient: this.useUser.paciente ? patient : null,
      orderDetails: this.cartStore.cartItems.map(item => ({itemId: item.id, quantity: item.cantidad}))
    }
    const res = await OrdenServices.create(datos)
    
    if(!res.error) {
      this.linkWeb = res.registro.linkWeb
      this.linkWhatsApp = res.registro.linkWhatsapp
      this.ordenGenerada = res.registro
      this.showCompartir = true
    } else {
      this.agregarPaciente = false
      this.showCompartir = false
    }
   },
   cerrarQr() {
    this.showModalQr = false
    if(this.useUser.paciente) {
      this.useUser.existeUltimoPaciente(true)
    } else {
      this.useUser.existeUltimoPaciente(false)
    }
   },
   cerrarExito() {
    this.showModalExito = false
   },
   cerrarPDF() {
    this.showModalPDF = false
    if(this.useUser.paciente) {
      this.useUser.existeUltimoPaciente(true)
    } else {
      this.useUser.existeUltimoPaciente(false)
    }
    this.showModalExito = true
   },
   async accionCompartir(item) {
    if(item == 0) {
      try {
        if(!this.ordenGenerada.patient) {
          this.faltante = 1
          this.modalFaltante = true
        } else {
          window.open(this.linkWhatsApp, '_blank');
          this.showModalExito = true
        }
      } catch (error) {
        console.error('Error al abrir WhatsApp:', error);
      }
    }
    if(item == 1) {
      const textArea = document.createElement('textarea');
      textArea.value = this.linkWeb;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.showModalExito = true
        } else {
          this.permisosCopi= false
          console.error('Error al copiar usando execCommand.');
        }
      } catch (error) {
        this.permisosCopi = false;
        console.error('Error al copiar usando execCommand:', error);
      }
      document.body.removeChild(textArea);
    }
    if(item == 2) {
      try {
        if(!this.ordenGenerada.patient) {
          this.faltante = 2
          this.modalFaltante = true
        } else {
          await OrdenServices.sendSms({orderId:this.ordenGenerada.id})
          this.showModalExito = true
        }
      } catch (error) {
        console.log(error)
      } 
    }
    if(item == 3) {
      try {
        if(!this.ordenGenerada.patient) {
          this.faltante = 3
          this.modalFaltante = true
        } else {
          await OrdenServices.sendEmail({orderId:this.ordenGenerada.id})
          this.showModalExito = true
        }
      } catch (error) {
        console.log(error)
      }
    }
    if(item == 4) {
      this.generatePDF()
    }
    if(item == 5) {
      this.showModalQr = true
    }
   },
   editar() {
      this.showAgregar = true;
    },
    cerrarAgregar() {
      this.showAgregar = false
    },
    agregado() {
      this.showAgregar = false 
    },
    cerrarFaltante() {
      this.modalFaltante = false
    },
    async confirmarDato(data) {
      this.modalFaltante = false
      if(data.dato == 1) {
        const res = await OrdenServices.whatsapp({orderId:this.ordenGenerada.id, phone: data.valor})
        window.open(res.whatsapp.link, '_blank');
      } else if(data.dato == 2) {
        await OrdenServices.sendSms({orderId:this.ordenGenerada.id, phone: data.valor})
      } else {
        await OrdenServices.sendEmail({orderId:this.ordenGenerada.id, email: data.valor})
      }
    },
    async generatePDF() {
      this.descargando = true
      let creacion = moment(this.ordenGenerada.createdAt).format('DD/MM/YYYY')
      let birthdate = this.ordenGenerada?.patient ? moment(this.ordenGenerada.patient.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
      let paciente = this.getNombre
      let edad = this.calcularEdad 
      
      await axios.get('../formatorecetapdf.html')
        .then(response => {
          let contenido = ``
          for(const [index, item] of this.ordenGenerada.orderDetails.entries()) {
            contenido += 
            `
            <div class="card-estudio">
                <p class="nombre-estudio">${index+1}.-${item.description}</p>
                <span class="lbl-indicaciones">Indicaciones</span>
                <p class="txt-indicaciones">${item.preparation}</p>
                <p class="precio">
                <span class="quantity">(${item.quantity})</span> Precio: <span class="monto-precio">$${monto.formatPrice(item.totalPrice)}</span>
                </p>
            </div>
            `
            if (index == 6) {
              contenido +=
              `
              </div>
                <div class="footer-principal">
                  <img src="./logo-recetame.png" alt="logo" />
                  <div class="addr">
                      <br />
                      <p class="domicilio">
                          <span>Padre Mier 1380 Pte. esq. Nicolás</span><br />
                          <span>Bravo, Col. Maria Luisa, Centro, C.P.</span><br />
                          <span>64040, Monterrey N.L.</span>
                      </p>
                  </div>
                  <hr />
              </div>
              </div>
              <div class="salto-linea"></div>
            `
            contenido += this.hojaNueva(paciente, creacion, birthdate, edad )
            }
          }
          const htmlcontent = response.data
            .replace('{{ getNombre }}', paciente)
            .replace('{{ estudios }}', contenido)
            .replace('{{ getFecha }}', creacion )
            .replace('{{ getFechaNacimiento }}', birthdate)
            .replace('{{ calcularEdad }}', edad)

          const opt = {
            margin: [5, 5, 0, 5], // Márgenes superior, derecho, inferior, izquierdo
            filename: `Orden-${this.ordenGenerada.folio}` + '.pdf',
            image: {
              type: 'jpeg',
              quality: 0.98
            },
            html2canvas: {
              scale: 2, logging: true, dpi: 192, letterRendering: true 
            },
            jsPDF: {
              unit: 'mm',
              format: 'a4',
              orientation: 'portrait'
            }
          };
          html2pdf().from(htmlcontent).set(opt).save();
          setTimeout(() => {
            this.descargando = false
            this.showModalExito = true
          }, 100);
        })
        .catch(error => {
        console.error(error);
      });
    },
    hojaNueva(nombre, fecha, nacimiento, edad) {
      let hoja = `
      <div class="hoja">
        <div class="header-principal">
            <div class="headerSection">
                <div class="logoAndName">
                    <img src="./logo-recetame.png" alt="logo" width="113" height="56" />
                </div>
                <div class="addressAndName">
                    <p class="nombre">Dr. Armando Espinoza Valenzuela</p>
                    <p class="cedula">Cédula Profesional: 7368172</p>
                    <p class="universidad">UNIVERSIDAD AUTÓNOMA DE SINALOA</p>

                    <div class="addr">
                        <br />
                        <p class="domicilio">
                            <span>Padre Mier 1380 Pte. esq. Nicolás</span><br />
                            <span>Bravo, Col. Maria Luisa, Centro, C.P.</span><br />
                            <span>64040, Monterrey N.L.</span><br />
                            <span>Tel: 81 8150 2100</span>
                        </p>
                    </div>
                </div>
                <div class="codigoBarra">
                    <img class="cbarra" src="./img-codigo-barras.png" alt="" />
                </div>
            </div>
            <hr />
            <div class="headerSection">
                <div class="issuedTo">
                    <div class="header-datos-paciente">
                        <p class="title">
                            Nombre del paciente:
                            <span class="txt-nombre">${ nombre }</span>
                        </p>
                        <p class="title">
                            Fecha: <span class="txt">${ fecha }</span>
                        </p>
                    </div>
                    <div class="header-datos-paciente-edad">
                        <p class="title">
                            Fecha de nacimiento:
                            <span class="txt">${ nacimiento }</span>
                        </p>
                        <p class="title">Edad: <span class="txt">${ edad } </span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contenedor-receta">
      `
          return hoja
    },
  },
   beforeRouteLeave(to, from, next) {
    if (this.agregarPaciente) {
      this.agregarPaciente = false; // Cerrar el modal
      next(false); // Evitar la navegación
    } else {
      next();
    }
  },
   mounted() {
    window.addEventListener('popstate', () => {
      if (this.agregarPaciente) {
        this.agregarPaciente = false;
      }
    });
  },
  beforeUnmount() {
  },
  watch: {},
};
</script>
<style scoped>
.container {
  width: 100% !important;
}
.title {
  font: normal normal bold 22px/29px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--primary-color);
}

::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
.module-container {
  margin-top: 65px !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

.content {
  flex: 1; 
  padding: 20px; 
  overflow-y: auto;
}

</style>
<style>
@media (min-width: 1279px) {
  .content {
    width: 100%;
  }
}
</style>