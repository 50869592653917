<template>
  <section class="categorias-vue">
    <div class="category-slider">
      <div v-show="load" :class="load ? 'category-load': ''" v-for="i in 7" :key="i">
        <Skeleton width="70px" height="67px"></Skeleton>
      </div>
      <div
        v-show="!load"
        class="category"
        :class="{ active: category.id === selectedCategoryId }"
        v-for="category in categories"
        :key="category.id"
        @click="selectCategory(category.id)"
      >
        <div class="category-icon">
          <!-- {{category.icono}} -->
          <img :src="category.icono" alt="" />
        </div>
        <div class="name">
          <p class="category-name">{{ category.name }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Skeleton from "@components/General/Load/Skeleton.vue";

export default {
  name: "CategoriasView",
  components: {
    Skeleton,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    load: {
      type: Boolean,
      
    },
  },
  data() {
    return {
      selectedCategoryId: null,
    };
  },
  emits: ['categoriaSeleccionada'],
  beforeMount() {},
  methods: {
    selectCategory(id) {
      this.selectedCategoryId = id//this.selectedCategoryId === id ? null : id;
      if (this.selectedCategoryId != null){
        this.$emit('categoriaSeleccionada', this.selectedCategoryId)
        // this.getProductsByCategory(this.selectedCategoryId);
      }
    },
    getProductsByCategory(id) {
      console.log("Categories", id);
    },
  },
  watch: {
    categories() {
      if(this.categories.length > 0) {
      this.selectedCategoryId = this.categories[0].id
      this.$emit('categoriaSeleccionada', this.selectedCategoryId)
    }
    }
  }
};
</script>

<style scoped>
.categorias-vue {
  position: static;
}
.category-slider {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  padding: 0px 0 5px;
}
.category-load {
  border-radius: 4px;
  width: 70px;
  height: 67px;
  min-width: 70px;
  min-height: 67px;
  margin: 0px 10px;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  /* border: 2px solid black; */
  margin: 0px 10px;
  border-radius: 4px;
  width: 70px;
  height: 67px;
  min-width: 70px;
  min-height: 67px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001d;
  border-radius: 6px;
  opacity: 1;
}
.category:first-child,
.category-load:first-child {
  margin-left: 0;
}
.category:last-child,
.category-load:last-child {
  margin-right: 0;
}
.category.active {
  background-color: #88cce243;
}
.category-icon img {
  min-height: 30px;
}
.category-name {
  text-align: center;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 8px;
  overflow-wrap: break-word;
  white-space: normal;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--primary-color);
}

::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
.name {
  min-height: 15px;
  display: flex;
  align-items: center;
}
</style>