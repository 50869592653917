import api from '@services/api';
const service = {};
const URL = '/user';


service.getPerfil = async () => {
  try {
    const perfil = await api.get(`${URL}/profile`);
    return { error: false, perfil: perfil.data };
  } catch (error) {
    return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
  }
};

export default service;