<template>
  <div class="contenedor-footer" @click="openModal">
    <footer>
      <div class="izquierdo">
        <div class="circle-button">
          <p>{{cantidad}}</p>
          <div v-if="cantidad > 0" class="icon-circle">
            <i class="icon-addcarrito" alt="Carrito" />
          </div>
        </div>
      </div>
      <div class="derecho">
        <span class="title-carrito">Carrito</span>
        <span class="subtitle-carrito">{{ cantidad }} {{ cantidad == 1 ? ' estudio agregado' : ' estudios agregados'}}</span>
      </div>
    </footer>
  </div>
</template>
<script>
import { useCarritoStore } from "@store/carritoStore";
export default {
  name: "FooterCarritoServicios",
  components: {},
  emits: ['abrirCarrito'],
  props: {},
  mixins: [],
  data() {
    return {
      cartStore: useCarritoStore()
    };
  },
  beforeMount() {},
  computed: {
    cantidad() {
      return this.cartStore.totalItems
    }
  },
  methods: {
    openModal() {
      this.$emit('abrirCarrito')
    }
  },
  created() {},
  unmounted() {},
  watch: {},
};
</script>
<style scoped>
.contenedor-footer {
  width: 100%;
  display: flex;
  margin: auto;
  transform: translateX(0px);
  position: fixed;
  bottom: 0;
}
footer {
  position: relative;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  height: 92px;
  width: 100%;
  display: flex;
  border-radius: 15px;
  clip-path: polygon(
    0 100%,
    0 0,
    35% 0%,
    40% 20%,
    60% 20%,
    65% 0%,
    100% 0,
    100% 100%,
    0% 100%
  );
}

.izquierdo {
  display: flex;
  margin-left: 30px;
  margin-right: 16px;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.derecho {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  padding: 10px 0px 0px;
}
.circle-button {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.icon-circle {
  width: 16px;
  height: 16px;
  background-color: #1035d3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -3px;
  right: -3px;
}

.icon-circle img {
  width: 60%; /* Ajusta el tamaño del icono */
  height: 60%;
}
.icon-addcarrito {
  font-size: 10px;
  color: #ffffff;
}
.title-carrito {
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.subtitle-carrito {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.49;
}
@media (min-width: 767px) {
  footer {
    /* margin: auto; */
    width: 60%;
  }
}
@media (min-width: 1279px) {
  footer {
    /* margin: auto; */
    width: 40%;
  }
}
</style>