<template>
  <div class="splash-screen">
    <div class="splash-content">
      <img src="@assets/logo-recetame.svg" alt="" class="logo">
      <p>Recetame</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "SplashScreen",
  components: {},
  emits: [],
  props: {},
  mixins: [],
  data() {
    return {};
  },
  beforeMount() {},
  computed: {},
  methods: {},
  created() { },
  unmounted() { },
  watch: {},
};
</script>
<style scoped>

.logo {
  width: 60%;
}

p {
  font: normal normal bold 24px/13px "Roboto";
  letter-spacing: 0px;
  opacity: 0.8;
}

.splash-content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url("@assets/fondo_recetame.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.splash-screen {
  height: calc(100vh - 290px);
}

</style>
