import { defineStore } from 'pinia';

export const useCarritoStore = defineStore('carrito', {
    state: () => ({
        items: [],
    }),
    getters: {
        totalItems: (state) => state.items.reduce((acc, item) => acc + item.cantidad, 0),
        totalPrecio: (state) => state.items.reduce((acc, item) => acc + item.cantidad * item.price, 0),
        cartItems: (state) => state.items,
    },
    actions: {
        iniciarCarrito() {
            const savedItems = localStorage.getItem('cartItems');
            if (savedItems) {
                this.items = JSON.parse(savedItems);
            }
        },
        guardarCarrito() {
            localStorage.setItem('cartItems', JSON.stringify(this.items));
        },
        addItem(servicio) {
            const existingItem = this.items.find(item => item.id === servicio.id);
            if (existingItem) {
                existingItem.cantidad += 1;
            } else {
                this.items.push({ ...servicio, cantidad: 1 });
            }
            this.guardarCarrito();
        },
        removeItem(productId) {
            this.items = this.items.filter(item => item.id !== productId);
            this.guardarCarrito();
        },
        sumarCantidad(productId) {
            const item = this.items.find(item => item.id === productId);
            if (item) item.cantidad += 1;
            this.guardarCarrito();
        },
        restarCantidad(productId) {
            const item = this.items.find(item => item.id === productId);
            if (item) {
                if (item.cantidad > 1) {
                    item.cantidad -= 1;
                }
                 else {
                    // this.removeItem(productId);
                    item.cantidad = 1;
                }
            }
            this.guardarCarrito();
        },
        vaciarCarrito() {
            this.items = [];
            this.guardarCarrito();
          },
    },
});